html {
  height: 100%;
}

body {
  height: 100%;
  box-sizing: content-box;
  width: 100%;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

#root {
  height: 100%;
}

.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 4rem 0;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4rem;
}

.tabs .tab {
  background-color: #fafafa;
  color: #6772e5;
  transition: 0.5s all ease;
  margin-right: 1rem;
}

.tabs .tab:hover {
  background-color: #6772e5;
  color: #fff;
  transition: 0.5s all ease;
}

.tabs .tab.active {
  background-color: #6772e5;
  color: #fff;
}

.tabs .tab:last-of-type {
  margin-right: 0;
}

label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  display: block;
}

label.forCheckbox {
  display: inline-block;
  margin-bottom: 1em;
  margin-right: 0.25em;
  cursor: pointer;
}

button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  user-select: none;
}

button:disabled {
  box-shadow: none;
  opacity: 0.5;
  pointer-events: none;
}

button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

textarea,
input:not([type='checkbox']),
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  width: 100%;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

input[type='checkbox'] {
  cursor: pointer;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

.block {
  max-width: 500px;
}

@media screen and (max-width: 600px) {
  .block {
    max-width: 350px;
  }
}

.error {
  color: #e12626;
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
}

.info {
  margin-top: 2rem;
  color: #6b7c93;
}

.info li {
  position: relative;
  margin-bottom: 0.5rem;
}

.info svg {
  margin-left: 0.4rem;
  fill: #6b7c93;
  cursor: pointer;
}

.info svg:hover {
  fill: #6772e5;
}

.info span {
  display: block;
  font-style: italic;
  margin-top: 1rem;
}

.alert {
  position: absolute;
  right: 0;
  top: 0;
  background: #5c68e3;
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
}